<template>
    <div>
        <div class="d-flex justify-space-between align-center pb-5">
            <p style="font-size: 18px;"
               class="font-weight-semibold text-black">
                {{ $t('Users Mangement') }}
            </p>
            <!-- <v-btn color="primary" class="me-3 text-lower" :to="{ name: 'user-form', params: { type: 'user', id: 'add' } }">

                <span>Add Users</span>
            </v-btn> -->
        </div>
        <v-divider></v-divider>
        <v-card id="user-list"
                class="mt-6">
            <!-- search -->
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="text-green font-weight-semibold text-base">{{ $t('App Users Listings') }}</span>
                    <!-- </div> -->
                </div>



                <div class="d-flex align-center pb-5">

                    <!-- <v-text-field v-model="searchQuery" single-line dense outlined hide-details
                        prepend-inner-icon="mdi-sort-variant" append-icon="mdi-chevron-down" placeholder="Sort by Status"
                        class="user-list-search me-3"></v-text-field> -->
                    <v-select :items="statusOptions"
                              single-line
                              dense
                              outlined
                              hide-details
                              v-model="searchQuery"
                              :label="$t('Status')"
                              @change="onChangeFilter()"
                              item-value="id"
                              item-text="name"
                              class="user-list-search me-3"></v-select>

                    <v-text-field v-model="searchText"
                                  single-line
                                  dense
                                  outlined
                                  prepend-inner-icon="mdi-magnify"
                                  hide-details
                                  :placeholder="$t('Search')"
                                  @input="onSearch()"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                </div>
            </v-card-text>

            <!-- table -->
            <v-data-table :search="search"
                          v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="userListTable"
                          :options.sync="options"
                          :footer-props="{ itemsPerPageOptions: [5, 10] }"
                          :server-items-length="totalUserListTable"
                          :loading="loading"
                          :class="{ 'light-mode': shouldApplyClass, 'dark-mode': !shouldApplyClass }"
                          class="text-no-wrap my-data-table">
                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }">

                    <div class="font-size text-black">{{ item.id }}</div>

                </template>
                <template #item.name="{ item }">
                    <div class="text-black font-size">{{ item.name }}</div>
                </template>
                <template #item.email="{ item }">
                    <div class="text-black font-size">{{ item.email }}</div>
                </template>
                <template #item.phone="{ item }">
                    <div class="text-black font-size">{{ item.phone }}</div>
                </template>

                <template #[`item.role_id`]="{ item }">
                    {{ resolveRole(item.role_id) }}
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <!-- view -->

                        <template bottom>
                            <v-switch v-model="item.status"
                                      inset
                                      @click="updateUserStatus(item.status, item.id)"
                                      value="1">
                            </v-switch>
                        </template>


                        <v-tooltip bottom>
                            <!-- :to="{ name: 'user-form', params: { type: 'user', state: 'view', id: item.id } }" -->
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       @click="viewUser(item)"
                                       class="action-btn"
                                       v-on="on">
                                    <v-icon size="20"
                                            color="#00B2A9"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js';
import darkMode from '@core/utils/modalColor';
// composition function
import router from '@/router';
import userServices from '@/services/user-services';
import useUsersList from './useUsersList';
import store from '@/store';

export default {
    data: () => ({
        applicationList: '',
        status: '1',
        shouldApplyClass: true,
        statusOptions: [
            {
                id: '',
                name: 'All'
            },
            {
                id: 1,
                name: 'Active'
            },
            {
                id: 0,
                name: 'InActive'
            }]
    }),

    created()
    {
        store.subscribe((mutation) =>
        {
            // console.log(mutation)
            if (mutation.type === 'accounts/SET_DARK_MODE')
            {
                this.shouldApplyClass = mutation.payload;
                // return {
                //     'my-class': this.shouldApplyClass // Conditionally apply 'my-class' based on 'shouldApplyClass' value
                // };
            }
        })
    },

    mounted()
    {
        let isDark = localStorage.getItem('my-active-theme');
        if (isDark == 'dark')
        {
            this.shouldApplyClass = false;
        }
    },

    methods: {
        viewUser(item)
        {
            router.push(`/user/${item.id}`);
        },
        updateUserStatus(status, id)
        {
            let url;
            let body;
            if (status == '1')
            {
                url = 'users/accept'
                body = {
                    "id": id,
                    "status": "1"
                }
            }
            else
            {
                url = 'users/reject'
                body = {
                    "id": id,
                    "status": "2"
                }
            }
            userServices.updateStatus(url, body).then(resp =>
            {
                // console.log(resp);
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
                else
                {
                    let status = resp.data.user.status == '1' ? 'User has been activated successfully' : 'User has been deactivated successfully';
                    this.$swal({
                        text: `${status}`,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {

                        // refresh here
                    })
                }
            })
        }
    },
    setup()
    {
        const {
            userListTable,
            searchQuery,
            searchText,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
            onSearch
        } = useUsersList()

        return {
            tableColumns,
            searchQuery,
            searchText,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
            onSearch,
            icons: {
                mdiEyeOutline
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

// ::v-deep .v-input--selection-controls {
//     margin-top: 0px;
//     padding-top: 4px !important;
// }


.font-size {
    font-size: 14px;
    font-weight: 400;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


// ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
// .v-data-table>.v-data-table__wrapper>table>thead>tr>td,
// .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
//     font-size: 0.875rem;
//     height: 3.125rem;
//     border-left: 1px solid #efeded !important;
// }

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

// ::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgba(94, 86, 105, 0.14);
//     border-right: 1px solid #efeded;
// }
</style>
